export default function initBurgerClick(){
    const header = document.querySelector('header.header');
    const burger = header.querySelector('.header-body__menu');
    window.addEventListener('click', e => {
        const clickBurger = e.target.closest('.header-body__menu');
        if(clickBurger === burger){
            header.classList.toggle('open');
            document.querySelector('body').classList.toggle('hide');
        } else {
            header.classList.remove('open');
            document.querySelector('body').classList.remove('hide');
        }
    });
}