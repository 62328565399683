import '../styles/index.scss';
window.App = {};
import initBurgerClick from "./components/_initBurgerClick";
import initGlide from "./components/_initGlide";
import initLightBox from "./components/_initLightbody";


document.addEventListener('DOMContentLoaded', ()=>{
    // init burger click and toggle header class after click
    initBurgerClick();


    // intro homepage slider
    const introGlideSlider = document.querySelector('.intro .glide');
    if (introGlideSlider){
        initGlide('introHomeSlider', introGlideSlider, {
            type: 'carousel',
            animationDuration: 1200,
            gap: 0,
            autoplay: 4000
        });
    }

    // sclad homepage slider
    const scladGlideSlider = document.querySelector('.glide-sclad');
    if (scladGlideSlider){
        const ops = {
            type: 'carousel',
            animationDuration: 1200,
            gap: 35,
            autoplay: 4000,
            perView: 4,
            breakpoints: {
                1459: {
                    perView: 3
                },
                991: {
                    perView: 2
                },
                768: {
                    perView: 1
                },
            }
        };
        initGlide('scladHomeSlider', scladGlideSlider, ops);
    }

    const lightBox = document.querySelectorAll('.glightbox');
    if(lightBox.length){
        initLightBox('cerf', {
            loop: true
        });
    };


});
